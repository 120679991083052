import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import WorkoutTimerIcon from "../images/WorkoutTimerIcon.png"

const WTPrivacyPolicyJP = () => (
    <Layout>
        <Seo title="Application" />
        <div className="main">
			<section className="contents">
				<h2>プライバシーポリシー</h2>
				<p>WorkoutTimerをAppleが運営するAppStoreより配信するにあたり、個人情報保護の重要性を認識し徹底した個人情報の保護を推進いたします。</p>
                <img className="padding-top_10vh padding-bottom_10vh" src={WorkoutTimerIcon} alt="Workout Timer Icon" width="150"/>
				
				<h3>個人情報の利用目的</h3>
				<p>サポートの際に個人情報をご連絡いただく場合がございますが、ご連絡やご質問に対する回答として電子メールや資料の送付等の目的以外では利用いたしません。</p>
				
				<h3>個人情報の安全性</h3>
				<p>個人情報の安全性確保のためにセキュリティ対策を講じています。</p>
				
				<h3>利用資格</h3>
				<p>WorkoutTimerはプライバシーポリシーに同意できる場合にご利用いただけます。<br />
				同意できない場合や同意を撤回する場合は直ちにWorkoutTimerの利用を中止し、削除してください。</p>
				
				<h3>免責事項</h3>
				<p>WorkoutTimerを使用して生じたいかなる損失・損害について、当方では責任を負いかねます。予めご了承ください。</p>
				
				<h3>お問い合わせ</h3>
				<p>個人情報に関するお問い合わせは下記までご連絡ください。<br />
				セキュリティーの都合上、サーバー側でのチェック時にウイルスが発見されたメールは自動的に削除されます。予めご了承ください。</p>
				<p><a href="https://blog.ushiya.net/このブログについて/#toc6" style={{color:'#b13223'}}>お問合せはこちらよりお願いします。</a>（当サイトのブログの問い合わせフォーム）</p>
				
				<h3>WorkoutTimer・プライバシーポリシーの変更</h3>
				<p>当方が必要と判断した場合には、事前の通知なくいつでもWorkoutTimer・プライバシーポリシーを変更できるものとします。</p>
		
				<p>更新日：2019.8.8</p>
            </section>
        </div>
    </Layout>
  )
  
  export default WTPrivacyPolicyJP